import React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Img from "gatsby-image";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "./Layout";
import kellyHeadshotSrc from "./assets/images/kelly_bitmoji.png";
import scottHeadshotSrc from "./assets/images/scott_bitmoji.png";
import generatePageTitle from "./components/Page/generatePageTitle";

export const query = graphql`
  query($id: String!) {
    recipe: mdx(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
      content: body
    }
  }
`;

const RecipeContentContainer = styled.main`
  padding: 2em;
  .gatsby-image-wrapper {
    max-width: 600px;
    margin: auto;
  }
  @media screen and (min-width: 768px) {
    padding: 3em 2em;
  }
`;

const RecipeTitle = styled.h2`
  color: ${(props) => props.theme.colors.text};
  max-width: 600px;
  margin: 0 auto 0.5em auto;
  text-align: center;
  font-size: 18px;
  line-height: 1.6em;
  @media screen and (min-width: 768px) {
    font-size: 22px;
  }
`;

const RecipeMain = styled.div`
  margin: 0 auto;
`;

const StyledAuthorInfo = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 2em;
`;

const AuthorImage = styled.img`
  height: 60px;
`;

const StyledRecipeContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 1024px) {
    grid-column-gap: 1em;
  }
`;

const RecipeSection = styled.section`
  margin-bottom: 1em;
`;

const SectionHeader = styled.h4`
  margin-top: 2em;
  margin-bottom: 1em;
  color: hsl(0, 0%, 10%);
`;

const Ingredients = styled.ul`
  margin: 0.25em 0em;
  list-style: none;
  padding-left: 0;
  li {
    position: relative;
    margin: 1em 0em;
    padding-left: 2em;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      height: 2px;
      width: 22px;
      border-radius: 5px;
      background-color: currentColor;
    }
  }
`;

const Steps = styled.div`
  line-height: 1.8em;
  p {
    margin: 1.5em 0em;
    &:first-child {
      margin-top: 1em;
    }
  }
`;

type RecipeProps = {
  location?: {
    pathname?: string;
  };
  data: {
    recipe: {
      frontmatter: {
        title: string;
        image: {
          childImageSharp: {
            fluid: {
              base64: string;
              aspectRatio: number;
              src: string;
              srcSet: string;
              sizes: string;
            };
          };
        };
      };
      content: string;
    };
  };
};

type AuthorInfoProps = {
  author: "Scott" | "Kelly";
};

const AuthorInfo = ({ author }: AuthorInfoProps) => {
  return (
    <StyledAuthorInfo>
      <AuthorImage
        src={author === "Scott" ? scottHeadshotSrc : kellyHeadshotSrc}
      />
      {`By ${author}`}
    </StyledAuthorInfo>
  );
};

type RecipeContentProps = {
  ingredients: string[];
  steps: string[];
};

const RecipeContent = ({ ingredients, steps }: RecipeContentProps) => {
  return (
    <StyledRecipeContent>
      <RecipeSection>
        <SectionHeader>Ingredients</SectionHeader>
        <Ingredients>
          {ingredients.map((ingredient) => (
            <li key={ingredient}>{ingredient}</li>
          ))}
        </Ingredients>
      </RecipeSection>
      <RecipeSection>
        <SectionHeader>Process</SectionHeader>
        <Steps>
          {steps.map((step) => (
            <p key={step}>{step}</p>
          ))}
        </Steps>
      </RecipeSection>
    </StyledRecipeContent>
  );
};

const shortcodes = {
  AuthorInfo,
  RecipeContent,
};

const Recipe: React.FC<RecipeProps> = ({ data, location }) => {
  const recipe = {
    title: data.recipe.frontmatter.title,
    image: data.recipe.frontmatter.image,
    content: data.recipe.content,
  };

  const title = `${generatePageTitle("Recipe")} | ${recipe.title}`;

  return (
    <>
      <Helmet title={title} />
      <Layout title={recipe.title} path={location?.pathname ?? ""}>
        <RecipeContentContainer>
          <RecipeTitle>{recipe.title}</RecipeTitle>
          <Img
            fluid={{ ...recipe.image.childImageSharp.fluid, aspectRatio: 1 }}
          />
          <RecipeMain>
            <MDXProvider components={shortcodes}>
              <MDXRenderer>{recipe.content}</MDXRenderer>
            </MDXProvider>
          </RecipeMain>
        </RecipeContentContainer>
      </Layout>
    </>
  );
};

export default Recipe;
